import React, { useState, useRef } from "react"
import { navigate } from "gatsby"
import { API_URL, setValToLS } from "../../utils/helpers"
import Input from "../../components/Input"
import Checkbox from "../../components/Checkbox"
import Msg from "../../components/Msg"
import Submit from "../../components/Submit"
import * as styles from "./styles.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCheckCircle,
  faChevronCircleRight,
} from "@fortawesome/pro-light-svg-icons"

const Four = ({ submitted }) => {
  const [names, setNames] = useState({})
  const [errors, setErrors] = useState([])
  const [msg, setMsg] = useState({})

  const formElement = useRef(null)

  const handleUpdate = (name, value) => {
    if (name === "remember") {
      setNames(names => ({ ...names, [name]: !value }))
    } else {
      setNames(names => ({ ...names, [name]: value ? value : "" }))
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setErrors([])
    setMsg({
      type: "working",
      text: "",
    })

    const url = new URL(`${API_URL}/auth/signin`)
    let data = new FormData()
    Object.entries(names).forEach(([key, value]) => {
      if (key === "remember") {
        data.append(key, value ? "1" : "0")
      } else {
        data.append(key, value)
      }
    })
    data.append("token", submitted.token)

    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
      })
      const json = await response.json()
      handleResponse(json, null)
    } catch (error) {
      handleResponse(null, error)
    }
  }

  const handleResponse = (json, error) => {
    if (json) {
      if (json.resp === 1) {
        if (json.id) {
          setValToLS("etfsrId", json.id)
        }
        setValToLS("etfsrToken", json.token, true)
        navigate("/sub")
      } else {
        setErrors(json.fields)
        setMsg({
          type: "error",
          text: json.text,
        })
      }
    } else {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      })
    }
  }

  return (
    <section className={styles.four}>
      <form
        method="post"
        action="/"
        onSubmit={e => handleSubmit(e)}
        ref={formElement}
      >
        <p>
          <FontAwesomeIcon icon={faCheckCircle} size="lg" />
          <span>
            Please enter the access code that was emailed to {submitted.email}{" "}
            (note that it may have gone to your spam folder).
          </span>
        </p>

        <div className="two">
          <Input
            name="code"
            label="Access Code"
            reqd={true}
            autocomplete="code"
            update={handleUpdate}
            errors={errors}
          />

          <div className="empty" />
        </div>

        <div className="one">
          <Checkbox
            name="remember"
            label="Check to stay signed in on this device"
            reqd={false}
            click={handleUpdate}
            checked={names.remember || false}
          />
        </div>

        <div className="msgSubmit">
          {msg.type && <Msg data={msg} />}

          <div className={msg.type === "working" ? "hidden" : ""}>
            <Submit name="Submit Access Code" icon={faChevronCircleRight} />
          </div>
        </div>
      </form>
      )}
    </section>
  )
}
export default Four
