import React from "react"
import { Link } from "gatsby"
import logo from "../../img/logo-tm.svg"
import * as styles from "./styles.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter } from "@fortawesome/free-brands-svg-icons"
import { faEnvelope } from "@fortawesome/pro-light-svg-icons"

const Footer = () => {
  return (
    <div className={styles.container}>
      <div className={styles.grid}>
        <Link to="/" className={styles.logo}>
          <img src={logo} alt="ETF Stock Report Logo" />
        </Link>

        <div className={styles.socialContainer}>
          <h3>Social Media / Contact</h3>

          <div className={styles.iconsContainer}>
            <a
              href="https://twitter.com/etfstockreport"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faTwitter} size="lg" />
            </a>

            <Link to="/contact">
              <FontAwesomeIcon icon={faEnvelope} size="lg" />
            </Link>
          </div>
          <p>
            177 N. US Hwy 1 Unit #288
            <br />
            Tequesta, FL 33469
          </p>
        </div>

        <div className={styles.socialContainer}>
          <h3>Legal Links</h3>

          <div className={styles.linksContainer}>
            <Link to="/privacy">Privacy Policy</Link>
            <Link to="/terms">Terms & Conditions</Link>
            <Link to="/accessibility">Accessibility Statement</Link>
          </div>
        </div>
      </div>

      <p style={{ marginTop: "20px" }}>
        ETF Stock Report is located in South Florida, serving Florida investors
        in West Palm Beach, Fort Lauderdale, Miami, and stock traders
        Nationwide.
      </p>

      <p className={styles.disclaimer}>
        This copyrighted publication is published on financial market trading
        days by Celestial Creative Solutions, LLC., and is intended solely for
        use by designated recipients. No reproduction, retransmission, or other
        use of the information, images, graphs, or tables is permitted. Analysis
        is developed from data believed to be accurate, but such accuracy or
        completeness cannot be guaranteed. It should not be assumed that such
        analysis, past or future, will be profitable or will equal past
        performance or guarantee future performance or trends. All trading and
        investment decisions are the sole responsibility of the
        reader/investor/user. Inclusion of information about managed accounts
        program positions and other information is not intended as any type of
        recommendation. An advisor/client relationship is not created by the
        distribution or delivery of the reports. ETF Stock Report and Celestial
        Creative Solutions LLC., are not affiliated nor associated with Standard
        and Poor, Dow Jones, Nasdaq, nor CBOE-Chicago Board of Options Exchange
        (VIX). We reserve the right to refuse service to anyone for any reasons.
        The principals of Celestial Creative Solutions may have positions in the
        markets covered. Subscription cost: $50 per month. Subscribers paying
        monthly agree to accept automatic subscription renewal by credit/ debit
        card.{" "}
      </p>
      <div className={styles.copy}>
        <p>
          <span>©</span>
          <span>
            2021-22, Celestial Creative Solutions, LLC. All Rights Reserved.
          </span>
        </p>
      </div>
    </div>
  )
}

export default Footer
