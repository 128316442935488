import React from "react"
import { isFieldError } from "../../utils/helpers"
import * as styles from "./styles.module.css"

const Input = ({
  type = "text",
  name,
  label,
  reqd,
  autocomplete = name,
  update,
  errors,
  value = "",
}) => {
  return (
    <div>
      <label htmlFor={name}>
        {`${label}`} {reqd ? <span className="error">*</span> : null}
      </label>
      {type === "file" ? (
        <input
          type="file"
          name={name}
          id={name}
          autoComplete={autocomplete}
          onChange={e => update(name, e.target.files[0])}
          className={
            isFieldError(errors, name)
              ? `${styles.input} ${styles.file} ${styles.error}`
              : `${styles.input} ${styles.file}`
          }
        />
      ) : (
        <input
          type={type}
          name={name}
          id={name}
          autoComplete={autocomplete}
          onChange={e => update(name, e.target.value)}
          className={
            isFieldError(errors, name)
              ? `${styles.input} ${styles.error}`
              : `${styles.input}`
          }
          defaultValue={value}
        />
      )}
    </div>
  )
}
export default Input
