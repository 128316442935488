import React from "react"
import * as styles from "./styles.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSquare, faCheckSquare } from "@fortawesome/pro-light-svg-icons"

const Checkbox = ({ name, label, reqd, click, checked, note = "" }) => {
  return (
    <div className={styles.checkbox}>
      <div>
        <button type="button" onClick={e => click(name, checked)}>
          <div>
            <FontAwesomeIcon icon={checked ? faCheckSquare : faSquare} />
            <span>
              {label} {reqd ? <span className={styles.error}>*</span> : null}
            </span>
          </div>
        </button>
      </div>
      <small className={note ? "" : "hidden"}>{note}</small>
    </div>
  )
}
export default Checkbox
