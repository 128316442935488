import React from "react"
import H1 from "../../components/H1"
import * as styles from "./styles.module.css"

const Two = () => {
  return (
    <section className={styles.two}>
      <H1 text="Subscriber Sign In"></H1>

      <div className={styles.grid}>
        <div>
          <span>
            Please use the form below to sign into your account. Not yet a
            subscriber? Click the subscribe today button above to sign up.
          </span>
        </div>
      </div>
    </section>
  )
}

export default Two
