import React from "react"
import * as styles from "./styles.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faTimesCircle,
  faExclamationCircle,
  faCheckCircle,
  faSpinnerThird,
} from "@fortawesome/pro-light-svg-icons"

const Msg = ({ data }) => {
  return (
    <div className={`${styles.container} ${styles[data.type]}`}>
      {data.type === "error" ? (
        <FontAwesomeIcon icon={faTimesCircle} size="lg" />
      ) : data.type === "success" ? (
        <FontAwesomeIcon icon={faCheckCircle} size="lg" />
      ) : data.type === "notify" ? (
        <FontAwesomeIcon icon={faExclamationCircle} size="lg" />
      ) : (
        <FontAwesomeIcon icon={faSpinnerThird} size="lg" spin />
      )}
      <span>{data.text}</span>
    </div>
  )
}

export default Msg
