import React, { useState, useRef } from "react"
import { Link } from "gatsby"
import { API_URL } from "../../utils/helpers"
import Input from "../../components/Input"
import Msg from "../../components/Msg"
import Submit from "../../components/Submit"
import * as styles from "./styles.module.css"
import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons"

const Three = ({ submit }) => {
  const [names, setNames] = useState({})
  const [errors, setErrors] = useState([])
  const [msg, setMsg] = useState({})

  const formElement = useRef(null)

  const handleUpdate = (name, value) => {
    setNames(names => ({ ...names, [name]: value ? value : "" }))
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setErrors([])
    setMsg({
      type: "working",
      text: "",
    })

    const url = new URL(`${API_URL}/signin`)
    let data = new FormData()
    Object.entries(names).forEach(([key, value]) => {
      data.append(key, value)
    })

    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
      })
      const json = await response.json()
      handleResponse(json, null)
    } catch (error) {
      handleResponse(null, error)
    }
  }

  const handleResponse = (json, error) => {
    if (json) {
      if (json.resp === 1) {
        submit({
          token: json.token,
          email: json.email,
        })
      } else {
        setErrors(json.fields)
        setMsg({
          type: "error",
          text: json.text,
        })
      }
    } else {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      })
    }
  }

  return (
    <section className={styles.three}>
      <form
        method="post"
        action="/"
        onSubmit={e => handleSubmit(e)}
        ref={formElement}
      >
        <p>
          Enter the email address of your subscriber account and we will email
          you a single-use access code. Should you have any questions, please{" "}
          <Link to="/contact" className="text">
            contact us
          </Link>
          .
        </p>

        <div className="two">
          <Input
            name="email"
            label="Email Address"
            reqd={true}
            autocomplete="email"
            update={handleUpdate}
            errors={errors}
          />

          <div className="empty" />
        </div>

        <div className="msgSubmit">
          {msg.type && <Msg data={msg} />}

          <div className={msg.type === "working" ? "hidden" : ""}>
            <Submit
              name="Submit Subscriber Email"
              icon={faChevronCircleRight}
            />
          </div>
        </div>
      </form>
    </section>
  )
}
export default Three
