import React, { useState, Fragment } from "react"
import Helmet from "../components/Helmet"
import One from "../signinPage/One"
import Two from "../signinPage/Two"
import Three from "../signinPage/Three"
import Four from "../signinPage/Four"
import Footer from "../components/Footer"

const SigninPage = () => {
  const [submitted, setSubmitted] = useState({
    token: "",
    email: "",
  })

  return (
    <Fragment>
      <Helmet
        title="Sign In"
        descr="Enter your email address to access your subscriber account or subscribe today to sign up for access."
        slug="/signin"
      />
      <One />
      <Two />
      {submitted.token ? (
        <Four submitted={submitted} />
      ) : (
        <Three submit={setSubmitted} />
      )}
      <Footer />
    </Fragment>
  )
}

export default SigninPage
